<!--
 * @Author: 林娴
 * @LastEditors: 林娴
-->


<script>
import iframeMixin from '../iframeMixin.vue'

export default {
  mixins: [iframeMixin],
  data() {
      return {
          urlParam: '',
          name: '',
      }
  },
  activated() {
      this.urlParam = this.$route.query.id
      this.name = this.$route.query.name
  },
  computed: {
    url() {
      console.log('name', this.name, this.urlParam)
     return `/ureport/preview?_u=mysql:${this.name}.ureport.xml&_i=1&eventId=${this.urlParam}`
    },
  },
}
</script>
